export const ROUTE_NAMES = {
  main: 'main',
  allServices: 'all-services',
  system: {
    defaultLayout: 'default-layout',
    rootLayout: 'root-layout',
    authGuard: 'auth-guard'
  },
  documents: {
    list: 'documents',
    create: 'documents-payment-order-create',
    edit: 'documents-payment-order-edit',

    template: {
      list: 'documents-template',
      create: 'documents-template-create',
      edit: 'documents-template-edit'
    },

    paymentRequirementsClosed: 'payment-requirements.closed',
    cardIndexesFirstIndex: 'card-indexes-first.index',
    cardIndexesFirstDetails: 'card-indexes-first.details',
    closedCardIndexesFirstDetails: 'closed-card-indexes-first.details',
    cardIndexesSecondIndex: 'card-indexes-second.index',
    cardIndexesSecondDetails: 'card-indexes-second.details',
    closedCardIndexesSecondDetails: 'closed-card-indexes-second.details',

    signing: 'documents-signing',
    history: 'documents-history',

    inBank: {
      list: 'documents-in-bank',
      details: 'documents-in-bank-details'
    }
  },
  swift: {
    list: 'swift-transfers',
    create: 'swift-transfers-create',
    edit: 'swift-transfers-edit',
    instruction: 'swift-transfers-instruction'
  },
  currency: {
    conversion: {
      list: 'currency-conversion',
      create: 'currency-conversion-create',
      details: 'currency-conversion-details'
    },
    exchange: {
      list: 'currency-exchange',
      create: 'currency-exchange-create',
      details: 'currency-exchange-details'
    }
  },
  contracts: {
    list: 'contracts'
  },
  salaryPayrolls: {
    list: 'salary-payrolls',
    create: 'salary-payrolls-create',
    createCopy: 'salary-payrolls-create-copy',
    edit: 'salary-payrolls-edit'
  },
  payments: {
    main: 'create-payment',
    createByType: 'create-payment-by-type',
    munis: 'munis',
    future: 'future-payments'
  },
  accountsCards: {
    list: 'accounts-and-cards',
    cardDetails: 'account-details',
    accountDetails: 'card-details'
  },
  inquiries: {
    list: 'inquiries',
    create: 'inquiries.create',
    statistics: 'inquiries.stats',
    employees: 'inquiries.employees',
    createEmployees: 'inquiries.employees.create'
  },
  deposits: {
    main: 'deposits',
    details: 'deposits.details',
    open: 'deposits.open',
    replenishment: 'deposits.replenishment'
  },
  dbo: {
    auth: {
      instruction: 'instruction'
    }
  },
  onboarding: {
    main: 'onboarding',
    registerApplication: 'register-application',
    auth: {
      leadFormAuth: 'lead-form-auth'
    }
  },
  additional: {
    employees: 'additional-employees'
  },
  statements: {
    list: 'statements'
  },
  settings: {
    default: 'settings',
    changePassword: 'change-password',
    changePhoneNumber: 'change-phone-number',
    changeEmail: 'change-email'
  },
  directories: {
    directories: 'directories',
    exchangeRates: 'exchange-rates',
    listBanks: 'list-banks',
    destinationCodes: 'destination-codes'
  }
} as const;

